.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the content covers the full viewport height */
  widows: 100%;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Main content container (all content except the footer) */
.work-content {
  flex: 1; /* Allows the main content to grow and push the footer down */
  padding-bottom: 40px; /* Add padding to ensure space above the footer */
  z-index: 2;
  width: 100%;
}

/* Main content container (all content except the footer) */
.hobbies-content {
  flex: 1; /* Allows the main content to grow and push the footer down */
  padding-bottom: 40px; /* Add padding to ensure space above the footer */
  z-index: 2;
  width: 100%;
}

/* Container for multiple ProjectCard components */
.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
  gap: 40px; /* Adjust this value for larger spacing */
  width: 80%; /* Center content in the middle 80% of the screen */
  margin: 40px auto; /* Center the container and add padding above and below */
  padding: 20px; /* Adds padding on all sides of the container */
  box-sizing: border-box; /* Ensures padding doesn’t affect width */
}


/* Footer styling (if you want to adjust padding or margin, do it here) */
.footer {
  margin-top: auto; /* Pushes the footer to the bottom if content is shorter than the viewport */
}

.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 60vh; /* Ensures the content covers at least 70% of the viewport height */
  padding: 40px 20px;
}
