/* Container for the floating background */
.floating-background {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: -1; /* Make sure it stays in the background */
  }
  
  /* Base styling for each floating object */
  .floating-object {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10%; /* Rounded corners to make them look like rounded squares */
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
    pointer-events: auto; /* Allow hover events */
  }
  
  /* Individual positions for each object */
  .object1 {
    top: 20%;
    left: 15%;
  }
  
  .object2 {
    top: 50%;
    left: 95%;
  }
  
  .object3 {
    top: 80%;
    left: 5%;
  }
  
  .object4 {
    top: 30%;
    left: 70%;
  }
  
  .object5 {
    top: 20%;
    left: 85%;
  }
  
  /* Hover effect to move objects slightly */
  .floating-object:hover {
    transform: translate(10px, -10px) scale(1.1);
  }
  