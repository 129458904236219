/* Main dropdown container */
.navbar-dropdown {
    position: relative;
    width: 100%;
  }
  
  /* Toggle button style (round button for the icons) */
  .toggle-button {
    background-color: transparent;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  
  /* Hamburger icon container */
  .hamburger-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    position: relative;
  }
  
  /* Hamburger lines */
  .hamburger-icon .line {
    width: 100%;
    height: 2px;
    background-color: #333;
    transition: transform 0.5s ease, top 0.5s ease, opacity 0.5s ease;
  }
  
  /* Top line initial position */
  .hamburger-icon .line.top {
    position: absolute;
    top: 6px;
  }
  
  /* Bottom line initial position */
  .hamburger-icon .line.bottom {
    position: absolute;
    top: 18px;
  }
  
  /* Transform to X when open */
  .hamburger-icon.open .line.top {
    transform: rotate(45deg);
    top: 12px;
  }
  
  .hamburger-icon.open .line.bottom {
    transform: rotate(-45deg);
    top: 12px;
  }
  
  /* Dropdown menu style */
  .dropdown-menu-custom {
    min-width: 120px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin-top: 10px; /* Space below the button */
    margin-left: -20px; /* Shift slightly to the left */
  }
  
  /* Individual item styling */
  .dropdown-item-custom {
    display: flex;
    padding: 8px 12px;
    color: #333;
    font-weight: 500;
    border-radius: 8px;
    transition: background-color 0.2s ease;
    cursor: pointer;
  }
  
  .dropdown-item-custom:hover {
    background-color: #f0f0f0;
  }
  
  /* Icon styling for each dropdown item */
  .icon {
    font-size: 1.2rem;
    margin-right: 8px;
    color: #555;
  }
  
  /* Text styling */
  .text {
    color: #333;
  }
  