/* Main container for the sticky navbar */
.about-page-navbar {
  position: sticky;
  top: 0;
  width: 100vh;
  background-color: transparent; /* Transparent to create a floating effect */
  z-index: 1000; /* Ensure it stays above other content */
  padding: 10px 20px;
}

/* Flex container to arrange items in a row */
.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5vw; /* Dynamic padding on the left and right edges */
}

/* Right-aligned NavBarDropdown with padding from the right edge */
.right-navbar {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 5vw;
  top: 1vw;
}
