/* Main container for the introduction */
.introduction {
    text-align: center;
    padding: 60px 20px;
    margin: 0 auto;
    color: #333;
    max-width: 100%;
  }
  
  /* Main heading */
  .intro-heading {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin: 0 0 20px 0;
  }
  
  /* Location text styling */
  .location {
    font-size: 1rem;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 20px 0;
  }
  
  /* Location icon styling */
  .location-icon {
    margin-right: 5px;
  }
  
  /* Introductory description text */
  .intro-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    max-width: 600px;
    width: 40vw;
    margin: 0 auto;
  }
  
  /* Link styling */
  .intro-text a {
    color: #007bff;
    text-decoration: none;
    font-weight: 500;
  }
  
  .intro-text a:hover {
    text-decoration: underline;
  }
  