/* Main container for the collage */
.photo-collage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 20px; /* Adjust gap to control spacing between images */
    margin: 20px auto;
    padding: 20px;
    max-width: 100%; /* Ensures the collage doesn't exceed the container width */
    flex-wrap: wrap;
    width: 100%;
  }
  
  /* Base styling for each image */
  .collage-image {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    transition: transform 0.3s ease;
    cursor: pointer;
    flex: 1 1 30%; /* Responsive width for each image (adjusts to 30% of container) */
    max-width: 25%; /* Maximum width for each image */
    height: auto;
  }
  
  /* Hover effect to enlarge slightly */
  .collage-image:hover {
    transform: scale(1.05);
  } 
  
  /* Individual positioning for each image */
  .collage-image-1 {
    position: relative;
    z-index: 1;
    transform: translateX(15%);
    zoom: relative;
    size: auto;
    
  }
  
  .collage-image-2 {
    position: relative;
    z-index: 2;
    transform: translateX(0%) 
    translateY(-10%);
  }
  
  .collage-image-3 {
    position: relative;
    z-index: 1;
    transform: translateX(-15%);
  }
  