/* Main container for the sticky navbar */
.main-page-navbar {
  position: sticky;
  top: 10px;
  width: 100vw;
  background-color: transparent; /* Transparent to create a floating effect */
  z-index: 1000; /* Ensure it stays above other content */
}

/* Flex container to arrange items in a row */
.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5vw; /* Dynamic padding on the left and right edges */
}

/* Center-aligned MiddlePillNavbar */
.middle-navbar {
  flex: 1;
  display: flex;
  justify-content: center;
}

/* Right-aligned NavBarDropdown with padding from the right edge */
.right-navbar {
  display: flex;
  justify-content: flex-end;
}


