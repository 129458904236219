/* Main card container */
.project-card {
    background-color: #f9f9f9;
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    width: 100%; /* Allows the card to take full width of the grid cell */
  }
  
  .project-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  }
  
  /* Card content layout */
  .card-content {
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 20px;
  }
  
  /* Text container taking up 70% of the card */
  .card-text {
    flex: 1; /* Takes up remaining space */
    width: 70%;
  }
  
  /* Image container taking up 30% of the card */
  .card-image {
    width: 30%; /* Set to 40% of the card's width */
    flex-shrink: 0; /* Prevents the image container from shrinking */
  }
  
  .card-image img {
    width: 100%; /* Makes the image fill the container */
    border-radius: 8px;
    object-fit: cover; /* Ensures the image fits well within the container */
  }
  
  /* Title styling */
  .card-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin: 0 0 8px 0;
  }
  
  /* Subtitle styling */
  .card-subtitle {
    font-size: 0.9rem;
    color: #777;
    margin: 0 0 16px 0;
  }
  
  /* Description styling */
  .card-description {
    font-size: 1rem;
    color: #555;
    margin-bottom: 16px;
  }
  
  /* Button styling */
  .card-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 0.9rem;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-decoration: none;
    transition: background-color 0.2s ease;
  }
  
  .card-button:hover {
    background-color: #f0f0f0;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .card-content {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .card-image {
      width: 100%; /* Makes the image full width on mobile */
    }
  
    .card-image img {
      max-width: 100%;
      margin-top: 10px;
    }
  
    .card-text {
      width: 100%; /* Text takes full width on mobile */
    }
  }
  