/* Main container for the introduction */
.introduction {
    text-align: center;
    padding: 60px 20px;
    max-width: 600px;
    margin: auto auto;
    color: #333;
    width: 100vw;
  }
  
  /* Main heading */
  .about-heading {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    max-width: 500px;
    width: 80%;
    margin: auto auto 20px auto;
  }
  
  /* Introductory description text */
  .about-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    max-width: 500px;
    width: 80%;
    margin: auto auto 20px auto;
  }
  
  /* Link styling */
  .about-text a {
    color: #007bff;
    text-decoration: none;
    font-weight: 500;
  }
  
  .about-text a:hover {
    text-decoration: underline;
  }