/* Footer styling */
.footer {
    bottom: 0;
    width: 100vw;
    background-color: #f9f9f9;
    padding: 20px 0;
    text-align: center;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  }
  
  /* Social icons container */
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 10px;
  }
  
  /* Icon styling */
  .icon {
    font-size: 1.5rem;
    color: #555;
    transition: color 0.3s ease;
  }
  
  .icon:hover {
    color: #333;
  }
  
  /* Copyright text styling */
  footer p {
    color: #888;
    font-size: 0.9rem;
    margin: 0;
  }
  