/* Main container for the placeholder page */
.placeholder-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 60vh; /* Ensures the content covers at least 70% of the viewport height */
    padding: 40px 20px;
    width: 100vw;
  }
  
  /* Heading styling */
  .placeholder-heading {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin: 0 0 20px 0;
  }
  
  /* Subheading styling */
  .placeholder-subheading {
    font-size: 1.2rem;
    color: #666;
    max-width: 600px; /* Limits width for readability */
    line-height: 1.5;
    margin: 0;
  }
  