/* Container styling for the pill toggle */
.middle-pill-navbar-container {
position: relative;
display: flex;
background-color: #f0f0f0;
border-radius: 20px;
padding: 5px; /* Container padding for balance */
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
width: 200px;
overflow: hidden;
align-items: center;
}

/* Sliding background highlight */
.highlight {
position: absolute;
top: 50%;
transform: translateY(-50%);
width: 45%; /* Slightly less than 50% for padding */
height: 80%;
background-color: #ffffff;
border-radius: 15px;
transition: left 0.3s ease; /* Smooth sliding animation */
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

/* Initial position of the highlight */
.highlight-hobbies {
left: calc(50% + 2.5px); /* Adjusts for padding */
}

/* Base styling for each button */
.pill-button {
flex: 1;
border: none;
background: none;
padding: 10px 0;
font-size: 16px;
font-weight: 500;
color: #555;
cursor: pointer;
z-index: 1; /* Ensures buttons are above the highlight */
text-align: center;
transition: color 0.3s ease;
}

/* Active button styling */
.pill-button.active {
color: #333;
font-weight: bold;
}

/* Inactive button styling */
.pill-button:not(.active) {
color: #aaa;
}
